<template>
  <!--Banner-->
  <section class="bannermain position-relative">
    <figure class="mb-0 bgshape">
      <img src="@/assets/repay/images/homebanner-bgshape.png" alt="" class="img-fluid" />
    </figure>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-5 col-12">
          <div class="banner" data-aos="fade-right">
            <h6 v-html="$t('banner.disclaimer')"></h6>
            <h1 v-html="$t('banner.title')"></h1>
            <p class="banner-text" v-html="$t('banner.slogan')"></p>
            <div class="button">
              <router-link class="button_text" to="/registrarse">{{ $t("banner.register") }}</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-7 col-12">
          <div class="banner-wrapper">
            <figure class="mb-0 homeelement1">
              <img src="@/assets/repay/images/homeelement1.png" class="img-fluid" alt="" />
            </figure>
            <figure class="mb-0 banner-image">
              <img src="@/assets/repay/images/professional-man.png" class="img-fluid" alt="banner-image" />
            </figure>
            <figure class="mb-0 content img-bg">
              <img src="@/assets/repay/images/homebanner-img-bg.png" alt="banner-image-bg" />
            </figure>
            <figure class="mb-0 homeelement">
              <img src="@/assets/repay/images/homeelement.png" class="img-fluid" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  mixins: [],
  mounted() {},
  methods: {},
};
</script>
