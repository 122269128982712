<template>
  <!-- Page Content -->
  <div class="content success-page-cont main-layout login-layout">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <!-- Success Card -->
          <div class="card success-card">
            <div class="card-body">
              <div class="success-cont">
                <i class="fas fa-check"></i>
                <h3>Solicitud enviada</h3>
                <p>Un correo electrónico ha sido enviado a su buzón con las instrucciones para restablecer su contraseña.</p>
                <router-link to="/entrar" class="btn btn-primary view-inv-btn">Entrar</router-link>
              </div>
            </div>
          </div>
          <!-- /Success Card -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>

<style src="@/assets/scss/frontend/home.scss" lang='scss' scoped></style>

<script>
import global from "@/helpers/global";

export default {
  mixins: [global],
  mounted() {
    this.checkIfLoggedIn();
  }
};
</script>