<template>
  <!--Contact-->
  <section id="contact_us" class="message-section">
    <div class="container">
      <figure class="element1 mb-0">
        <img src="@/assets/repay/images/what-we-do-icon-1.png" class="img-fluid" alt="" />
      </figure>
      <div class="row position-relative">
        <div class="col-12">
          <div class="content">
            <h6 v-html="$t('contact_us.title')"></h6>
            <h2 v-html="$t('contact_us.description')"></h2>
            <figure class="element3 mb-0">
              <img src="@/assets/repay/images/what-we-do-element.png" alt="" class="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="message_content" data-aos="fade-up">
            <form id="contactpage" @submit.prevent="sendMessage">
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <h4 v-html="$t('contact_us.label_name')"></h4>
                    <input
                      v-model="contact.name"
                      type="text"
                      class="form_style"
                      name="name"
                      :placeholder="$t('contact_us.placeholder_name')"
                    />
                    <small v-if="errors.name" class="text-danger">{{ errors.name[0] }}</small>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <h4 v-html="$t('contact_us.label_email')"></h4>
                    <input
                      v-lower-case
                      v-model="contact.email"
                      type="email"
                      class="form_style"
                      name="emailid"
                      :placeholder="$t('contact_us.placeholder_email')"
                    />
                    <small v-if="errors.email" class="text-danger">{{ errors.email[0] }}</small>
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group mb-0">
                    <h4 v-html="$t('contact_us.label_phone')"></h4>
                    <input
                      v-model="contact.phone"
                      type="tel"
                      class="form_style"
                      name="phone"
                      :placeholder="$t('contact_us.placeholder_phone')"
                    />
                    <small v-if="errors.phone" class="text-danger">{{ errors.phone[0] }}</small>
                  </div>
                </div> -->
                <div class="col-12">
                  <div class="form-group mb-0">
                    <h4 v-html="$t('contact_us.label_message')"></h4>
                    <textarea
                      v-model="contact.message"
                      class="form_style"
                      rows="3"
                      name="msg"
                      :placeholder="$t('contact_us.placeholder_message')"
                    ></textarea>
                    <small v-if="errors.message" class="text-danger">{{ errors.message[0] }}</small>
                  </div>
                </div>
              </div>
              <div class="manage-button text-center">
                <button type="submit" class="submit" v-html="$t('contact_us.submit')"></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  components: {},
  data() {
    return {
      contact: {
        name: "",
        email: "",
        message: "",
        phone: "",
      },
      errors: [],
    };
  },
  mixins: [global],
  mounted() {},
  methods: {
    sendMessage(evt) {
      evt.preventDefault();

      this.errors = [];

      axios
        .post(process.env.VUE_APP_API_URL + "/api/v1/contact/send", this.contact)
        .then((response) => {
          this.contact = {
            name: "",
            email: "",
            message: "",
          };

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          }
        });
    },
  },
};
</script>
