<template>
  <div class="main-layout">
    <section class="position-relative">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="py-4">{{ $t("terms_conditions.title") }}</h1>
            <p>{{ $t("terms_conditions.1_block") }}</p>
            <p>{{ $t("terms_conditions.2_block") }}</p>
            <p>{{ $t("terms_conditions.3_block") }}</p>
            <h2 class="w-100 mb-4">{{ $t("terms_conditions.part1_title") }}</h2>
            <h3 class="w-100 mb-4">{{ $t("terms_conditions.part1_sub-title") }}</h3>
            <p>
              <span class="font-weight-bold">{{ $t("terms_conditions.part1_1_title") }}</span>
              {{ $t("terms_conditions.part1_1_body") }}
            </p>
            <p>
              <span class="font-weight-bold">{{ $t("terms_conditions.part1_2_title") }}</span>
              {{ $t("terms_conditions.part1_2_body") }}
            </p>
            <p>
              <span class="font-weight-bold">{{ $t("terms_conditions.part1_3_title") }}</span>
              {{ $t("terms_conditions.part1_3_body") }}
            </p>
            <p>
              <span class="font-weight-bold">{{ $t("terms_conditions.part1_4_title") }}</span>
              {{ $t("terms_conditions.part1_4_body") }}
            </p>
            <p>
              <span class="font-weight-bold">{{ $t("terms_conditions.part1_5_title") }}</span>
              {{ $t("terms_conditions.part1_5_body") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <SectionFooter />
  </div>
</template>

<script>
import SectionFooter from "@/components/sections/repay/Footer";
export default {
  components: {
    SectionFooter,
  },
};
</script>
