<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="termsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="termsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Términos y Condiciones</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <section id="terminos" class="divider">
            <div class="container">
              <div class="row">
                <p>Este acuerdo de usuario será efectivo para todos los usuarios a partir del 09 de agosto de 2020.</p>
                <p>¡Bienvenido a Aless Pay!</p>
                <p>
                  Vestibulum tortor diam, tempus sed dui id, maximus tristique metus. Proin et ligula eget neque
                  tristique ullamcorper eget sed turpis. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                  Aenean a lobortis ante, vel tincidunt risus. In eget auctor orci. Donec tincidunt mauris ut tellus
                  mollis, at mattis felis convallis.Este es un Acuerdo de usuario entre usted (también denominado en el
                  presente como "Cliente" o "Usuario") y Aless Pay. Este Acuerdo de usuario ("Acuerdo") rige su uso de
                  los servicios proporcionados por Aless Pay descritos a continuación. Al registrarse para usar una
                  cuenta a través de https://alesspay.com, usted acepta que ha leído, comprende y acepta todos los
                  términos y condiciones contenidos en este Acuerdo,
                </p>
                <h2 class="w-100 mb-4">Parte 1: Uso General</h2>
                <h3 class="w-100 mb-4">1.Configuración de la cuenta</h3>
                <p>
                  <span class="font-weight-bold">1.1. Elegibilidad.</span> Para ser elegible para utilizar los servicios
                  de Aless Pay, debe tener al menos 18 años.
                </p>
                <p>
                  <span class="font-weight-bold">1.2. Condiciones.</span>
                  Podemos enmendar o modificar este Acuerdo en cualquier momento publicando el acuerdo revisado en el
                  sitio de Aless Pay y / o proporcionándole una copia (un "Acuerdo revisado"). El Acuerdo revisado
                  entrará en vigencia a partir del momento en que se publique, pero no se aplicará retroactivamente. Su
                  uso continuado de los Servicios después de la publicación de un Acuerdo revisado constituye su
                  aceptación de dicho Acuerdo revisado. Si no está de acuerdo con dicha modificación, su único y
                  exclusivo recurso es terminar su uso de los Servicios y cerrar su cuenta.
                </p>
                <p>
                  <span class="font-weight-bold">1.3. Registro de cuenta.</span>
                  Debe registrarse para obtener una cuenta de Aless Pay para utilizar los servicios de Aless Pay. Al
                  utilizar una cuenta de Aless Pay, usted acepta y declara que utilizará Aless Pay solo para usted, y no
                  en nombre de un tercero. Usted es totalmente responsable de toda la actividad que se produzca en su
                  cuenta de Aless Pay. Podemos, a nuestra entera discreción, negarnos a abrir una Cuenta de Aless Pay, o
                  limitar el número de Cuentas de Aless Pay que puede mantener o suspender o cancelar cualquier Cuenta
                  de Aless Pay.
                </p>
                <p>
                  <span class="font-weight-bold">1.4. Verificación de identidad.</span>
                  Durante el registro de su cuenta de Aless Pay, acepta proporcionarnos la información que solicitamos
                  con el fin de verificar la identidad y la detección de lavado de dinero, financiamiento del
                  terrorismo, fraude o cualquier otro delito financiero y nos permite mantener un registro de tal
                  información. Deberá completar ciertos procedimientos de verificación antes de que se le permita
                  utilizar los servicios de Aless Pay. Su acceso a uno o más Servicios de Aless Pay y los límites que se
                  aplican a su uso de los Servicios de Aless Pay pueden verse alterados como resultado de la información
                  recopilada sobre usted de forma continua. La información que solicitamos puede incluir cierta
                  información personal, que incluye, entre otros, su nombre, dirección, número de teléfono, dirección de
                  correo electrónico, fecha de nacimiento, número de identificación del contribuyente, una
                  identificación del gobierno e información sobre su cuenta bancaria (como como el nombre del banco, el
                  tipo de cuenta, el número de ruta y el número de cuenta) y, en algunos casos (cuando lo permita la
                  ley), categorías especiales de datos personales, como su información biométrica. Al proporcionarnos
                  esta o cualquier otra información que pueda ser necesaria, usted confirma que la información es
                  precisa y auténtica. Acepta mantenernos actualizados si cambia la información que nos proporciona.
                  Usted nos autoriza a realizar las consultas, ya sea directamente o/a través de terceros, que
                  consideremos necesarias para verificar su identidad o protegerlo a usted y / o a nosotros contra el
                  fraude u otro delito financiero, y para tomar las medidas que consideremos razonablemente necesarias
                  en función de los resultados de tal consultas. Cuando llevamos a cabo estas consultas, usted reconoce
                  y acepta que su información personal puede ser divulgada a agencias de referencia crediticia y
                  prevención de fraude o delitos financieros y que estas agencias pueden responder a nuestras consultas
                  en su totalidad. Esta es solo una verificación de identidad y no debería tener ningún efecto adverso
                  en su calificación crediticia. Además, autoriza a su operador inalámbrico (AT&T, Sprint, T-Mobile, US
                  Cellular, Verizon o cualquier otro operador inalámbrico de marca) a usar su número de teléfono móvil,
                  nombre, dirección, correo electrónico, estado de la red, tipo de cliente, función del cliente,
                  facturación. tipo, identificadores de dispositivos móviles (IMSI e IMEI) y otros detalles del estado
                  del suscriptor, si están disponibles, únicamente para permitir la verificación de su identidad y para
                  comparar la información que ha proporcionado a Aless Pay con la información del perfil de su cuenta de
                  operador inalámbrico durante la duración del negocio relación.
                </p>
                <p>
                  <span class="font-weight-bold">1.5. Acceso.</span> Para acceder a los Servicios de Aless Pay, debe
                  tener el equipo necesario (como un teléfono inteligente o computadora portátil) y las suscripciones de
                  servicios de telecomunicaciones asociadas para acceder a Internet. Se puede acceder a los servicios de
                  Aless Pay directamente utilizando el sitio de Aless Pay (www.Aless Pay.com). El acceso a los Servicios
                  de Aless Pay puede degradarse o no estar disponible durante momentos de volatilidad o volumen
                  significativo. Esto podría resultar en la imposibilidad de comprar o vender durante períodos de tiempo
                  y también puede dar lugar a demoras en el tiempo de respuesta del soporte. Aunque nos esforzamos por
                  brindarle un excelente servicio, no representamos que el Sitio de Aless Pay estarán disponibles sin
                  interrupción y no garantizamos que ninguna orden será ejecutada, aceptada, registrada o permanecerá
                  abierta. Aless Pay no será responsable de ninguna pérdida que resulte o surja de retrasos en las
                  transacciones.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <!-- /Modal -->
</template>

<script>
export default {};
</script>
