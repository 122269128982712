<template>
  <div class="LayoutWallet">
    <div id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <!-- Main wrapper start -->
    <div id="main-wrapper">
      <!-- Nav header start -->
      <div class="nav-header">
        <a href="/" class="brand-logo">
          <img class="logo-full" src="@/assets/repay/images/aless-pay-logo.png" alt />
          <img class="logo-abbr" src="@/assets/repay/images/alesspay-logo.png" alt />
        </a>
        <div class="nav-control">
          <div class="hamburger">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </div>
      </div>
      <!-- Nav header end -->
      <!-- Header start -->
      <section-header></section-header>
      <!-- Header end ti-comment-alt -->
      <!-- Sidebar start -->
      <section-sidebar></section-sidebar>
      <!-- Sidebar end -->
      <!-- Content body start -->
      <div class="content-body">
        <slot />
        <section-copyright />
      </div>
      <!-- Content body end -->
      <!-- Footer start -->
      <!-- <section-footer></section-footer> -->
      <!-- Footer end -->
      <terms-popup></terms-popup>
    </div>
    <!-- Main wrapper end -->
  </div>
</template>

<style src="@/assets/scss/wallet/_global.scss" lang="scss"></style>
<style src="@/assets/scss/layout.scss" lang="scss" scoped></style>

<script>
import global from "@/helpers/global";

import SectionHeader from "@/components/sections/Header";
import SectionSidebar from "@/components/sections/Sidebar";
// import SectionFooter from "@/components/sections/Footer";

import TermsPopup from "@/components/TermsPopup";

/*Required vendors*/
import "bootstrap";
import "bootstrap-select";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "metismenu";
import "metismenu/dist/metisMenu.min.css";
import "owl.carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "datatables.net-responsive-dt";
import { Mophy, getUrlParams, dezSettings } from "@/assets/js/custom.js";
import SectionCopyright from "@/components/sections/repay/Copyright";

export default {
  components: {
    SectionHeader,
    SectionSidebar,
    // SectionFooter,
    SectionCopyright,
    TermsPopup,
  },
  mixins: [global],
  beforeMount() {},
  mounted() {
    const el = document.body;
    el.classList.add("wallet-loaded");

    Mophy.init();

    var direction = getUrlParams("dir");
    if (direction != "rtl") {
      direction = "ltr";
    }

    var dezSettingsOptions = {
      typography: "poppins",
      version: "light",
      layout: "Vertical",
      headerBg: "color_1",
      navheaderBg: "color_1",
      sidebarBg: "color_1",
      sidebarStyle: "full",
      sidebarPosition: "fixed",
      headerPosition: "fixed",
      containerLayout: "full",
      direction: direction,
    };

    new dezSettings(dezSettingsOptions);
  },
  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },
  },
  methods: {
    routeLoaded() {
      //Dom for the current route is loaded
      Mophy.load();
      $("#preloader").fadeOut(500);
    },
  },
};
</script>
