<template>
  <div class="">
    <div id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div class="main-wrapper">
      <section-header />
      <slot />
      <section-copyright />
    </div>
  </div>
</template>

<!-- <style src="@/assets/scss/frontend/style.scss" lang="scss"></style> -->

<script>
import SectionHeader from "@/components/sections/repay/Header";
import SectionCopyright from "@/components/sections/repay/Copyright";

export default {
  components: {
    SectionHeader,
    SectionCopyright,
  },
  mounted() {
    const el = document.body;
    el.classList.remove("wallet-loaded");

    $("#preloader").fadeOut(500);
  },
};
</script>
