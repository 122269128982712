<template>
  <!-- Home Banner -->
  <div class="main-layout">
    <Banner />
    <WhatWeDo />
    <AboutUs />
    <Services />
    <FAQ />
    <ContactUs />
    <SectionFooter />
  </div>
</template>

<style src="@/assets/repay/bootstrap/bootstrap.min.css" lang="css"></style>
<style src="@/assets/repay/css/style.css" lang="css"></style>
<style src="@/assets/repay/css/custom-style.css" lang="css"></style>
<style src="@/assets/repay/css/special-classes.css" lang="css"></style>
<style src="@/assets/repay/css/responsive.css" lang="css"></style>
/>

<script>
import global from "@/helpers/global";
import Banner from "@/components/sections/repay/Banner";
import AboutUs from "@/components/sections/repay/AboutUs";
import WhatWeDo from "@/components/sections/repay/WhatWeDo";
import Services from "@/components/sections/repay/Services";
import FAQ from "@/components/sections/repay/FAQ";
import ContactUs from "@/components/sections/repay/ContactUs";
import SectionFooter from "@/components/sections/repay/Footer";

export default {
  components: {
    Banner,
    AboutUs,
    WhatWeDo,
    Services,
    FAQ,
    ContactUs,
    SectionFooter,
  },
  data() {
    return {
      contact: {
        name: "",
        email: "",
        message: "",
      },
      errors: [],
    };
  },
  mixins: [global],
  mounted() {
    if (this.checkIfLoggedIn()) this.$router.push("/monedero");

    const scripts = [
      "assets/repay/js/jquery-3.6.0.min.js",
      // "assets/repay/js/bootstrap.min.js",
      // "assets/repay/js/video_link.js",
      // "assets/repay/js/video.js",
      "assets/repay/js/counter.js",
      "assets/repay/js/custom.js",
      "assets/repay/js/animation_links.js",
      "assets/repay/js/animation.js",
      "https://unpkg.com/aos@2.3.1/dist/aos.js",
    ];

    scripts.forEach((script) => {
      let tag = document.head.querySelector(`[src="${script}"`);
      if (!tag) {
        tag = document.createElement("script");
        tag.setAttribute("src", script);
        tag.setAttribute("type", "text/javascript");
        document.head.appendChild(tag);
      }
    });
  },
  methods: {},
};
</script>
