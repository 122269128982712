import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/helpers/auth";
import { EventBus } from "@/main";

// Add a response interceptor
/*axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log(response);
  //alert(1);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log(error);
  //alert(2);
  return Promise.reject(error);
});*/

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    //component: Home,
    component: require("@/components/Home.vue").default, // load sync home
    meta: {
      layout: "Page",
      title: "Aless Pay - La forma más rápida de hacer y recibir pagos.",
    },
  },
  {
    path: "/terminos-y-condiciones",
    name: "terms",
    //component: Terms,
    component: require("@/components/Terms.vue").default,
    meta: {
      layout: "Page",
      title: "Términos y Condiciones | Aless Pay",
    },
  },
  {
    path: "/privacidad",
    name: "privacy",
    //component: Privacy,
    component: require("@/components/Privacy.vue").default,
    meta: {
      layout: "Page",
      title: "Políticas de Privacidad | Aless Pay",
    },
  },
  {
    path: "/entrar",
    name: "entrar",
    //component: Login,
    component: require("@/components/Login/Login.vue").default,
    meta: {
      layout: "Page",
      title: "Autenticarte | Aless Pay",
    },
  },
  {
    path: "/recuperar-contrasena",
    name: "recuperar-contrasena",
    component: require("@/components/Login/ForgotPassword.vue").default,
    meta: {
      layout: "Page",
      title: "Recuperar contraseña | Aless Pay",
    },
  },
  {
    path: "/solicitud-enviada",
    name: "solicitud-enviada",
    component: require("@/components/Login/ForgotSuccess.vue").default,
    meta: {
      layout: "Page",
      title: "Contraseña enviada | Aless Pay",
    },
  },
  {
    path: "/resetear-contrasena/:email?/:code(\\d+)?",
    name: "resetear-contrasena",
    component: require("@/components/Login/ResetPassword.vue").default,
    meta: {
      layout: "Page",
      title: "Restablecer contraseña | Aless Pay",
    },
  },
  {
    path: "/contrasena-reseteada",
    name: "contrasena-reseteada",
    component: require("@/components/Login/ResetSuccess.vue").default,
    meta: {
      layout: "Page",
      title: "Contraseña restablecida | Aless Pay",
    },
  },
  {
    path: "/registrarse",
    name: "registrarse",
    component: require("@/components/Register.vue").default,
    meta: {
      layout: "Page",
      title: "Registrarse | Aless Pay",
    },
  },
  {
    path: "/registrar-usuario",
    name: "registrar-usuario",
    component: require("@/components/Person/Register.vue").default,
    meta: {
      layout: "Page",
      title: "Registrar usuario | Aless Pay",
    },
  },
  {
    path: "/usuario-creado",
    name: "usuario-creado",
    component: require("@/components/Person/RegisterSuccess.vue").default,
    meta: {
      layout: "Page",
      title: "Usuario registrado | Aless Pay",
    },
  },
  {
    path: "/completar-usuario/:email?/:code(\\d+)?",
    name: "completar-usuario",
    component: require("@/components/Person/CompleteRegister.vue").default,
    meta: {
      layout: "Page",
      title: "Registrar usuario | Aless Pay",
    },
  },
  {
    path: "/usuario-completado",
    name: "usuario-completado",
    component: require("@/components/Person/CompleteSuccess.vue").default,
    meta: {
      layout: "Page",
      title: "Usuario registrado | Aless Pay",
    },
  },
  {
    path: "/registrar-empresa",
    name: "registrar-empresa",
    component: require("@/components/Company/Register.vue").default,
    meta: {
      layout: "Page",
      title: "Registrar empresa | Aless Pay",
    },
  },
  {
    path: "/empresa-creada",
    name: "empresa-creada",
    component: require("@/components/Company/RegisterSuccess.vue").default,
    meta: {
      layout: "Page",
      title: "Empresa registrada | Aless Pay",
    },
  },
  {
    path: "/confirmar-email/:email?/:code(\\d+)?",
    name: "confirmar-email",
    component: require("@/components/Login/ConfirmEmail.vue").default,
    meta: {
      layout: "Page",
      title: "Confirmar email | Aless Pay",
    },
  },
  {
    path: "/email-confirmado",
    name: "email-confirmado",
    component: require("@/components/Login/ConfirmSuccess.vue").default,
    meta: {
      layout: "Page",
      title: "Email confirmado | Aless Pay",
    },
  },
  {
    path: "/monedero",
    name: "wallet",
    component: require("@/components/Wallet/Wallet.vue").default,
    meta: {
      layout: "Wallet",
      title: "Billetera | Aless Pay",
    },
  },
  {
    path: "/transacciones",
    name: "transacciones",
    component: require("@/components/Transactions/Index.vue").default,
    meta: {
      layout: "Wallet",
      title: "Transacciones | Aless Pay",
    },
  },
  {
    path: "/recarga-saldo",
    name: "recarga-saldo",
    component: require("@/components/Recharge/Index.vue").default,
    meta: {
      layout: "Wallet",
      title: "Recargas de saldo | Aless Pay",
    },
  },
  {
    path: "/recarga-saldo/nueva",
    name: "recarga-saldo-new",
    component: require("@/components/Recharge/New.vue").default,
    meta: {
      layout: "Wallet",
      title: "Recargar billetera | Aless Pay",
    },
  },
  {
    path: "/retiro-saldo",
    name: "retiro-saldo",
    component: require("@/components/Withdraw/Index.vue").default,
    meta: {
      layout: "Wallet",
      title: "Retiros de saldo | Aless Pay",
      rolePerson: false,
    },
  },
  {
    path: "/retiro-saldo/nuevo",
    name: "retiro-saldo-new",
    component: require("@/components/Withdraw/New.vue").default,
    meta: {
      layout: "Wallet",
      title: "Retirar saldo | Aless Pay",
      rolePerson: false,
    },
  },
  {
    path: "/transferencias",
    name: "transferencias",
    component: require("@/components/Transfers/Index.vue").default,
    meta: {
      layout: "Wallet",
      title: "Transferencias de saldo | Aless Pay",
    },
  },
  {
    path: "/transferencia/nueva",
    name: "transferencia-new",
    component: require("@/components/Transfers/New.vue").default,
    meta: {
      layout: "Wallet",
      title: "Transferir saldo | Aless Pay",
    },
  },
  {
    path: "/monedero/:username",
    name: "transferencia-monedero",
    component: require("@/components/Transfers/New.vue").default,
    meta: {
      layout: "Wallet",
      title: "Transferir saldo | Aless Pay",
    },
  },
  {
    path: "/perfil-empresa",
    name: "perfil-empresa",
    component: require("@/components/Company/Profile.vue").default,
    meta: {
      layout: "Wallet",
      title: "Perfil de empresa | Aless Pay",
    },
  },
  {
    path: "/perfil-usuario",
    name: "perfil-usuario",
    component: require("@/components/Person/Profile.vue").default,
    meta: {
      layout: "Wallet",
      title: "Perfil de usuario | Aless Pay",
    },
  },
  {
    path: "/contrasena",
    name: "contrasena",
    component: require("@/components/ChangePasswd.vue").default,
    meta: {
      layout: "Wallet",
      title: "Cambiar contraseña | Aless Pay",
    },
  },
  {
    path: "/notificaciones",
    name: "notifications",
    component: require("@/components/Notification/NotificationList.vue").default,
    meta: {
      layout: "Wallet",
      title: "Notificaciones | Aless Pay",
    },
  },
  {
    path: "/preguntas-frecuentes",
    name: "faqs",
    component: require("@/components/Faqs/Index.vue").default,
    meta: {
      layout: "Wallet",
      title: "Preguntas frecuentes | Aless Pay",
    },
  },
  // and finally the default route, when none of the above matches:
  {
    path: "*",
    //component: PageNotFound
    component: require("@/components/PageNotFound.vue").default,
    meta: {
      layout: "Blank",
      title: "Página no encontrada | Aless Pay",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const publicPages = [
  /^\/?$/,
  /^entrar\/?$/,
  /^recuperar-contrasena\/?$/,
  /^solicitud-enviada\/?$/,
  /^resetear-contrasena(\/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\/\d+)?\/?$/,
  /^contrasena-reseteada\/?$/,
  /^registrarse\/?$/,
  /^registrar-(usuario|empresa)\/?$/,
  /^confirmar-email(\/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\/\d+)?\/?$/,
  /^completar-usuario(\/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\/\d+)?\/?$/,
  /^terminos-y-condiciones\/?$/,
  /^privacidad\/?$/,
  /^usuario-creado\/?$/,
  /^usuario-completado\/?$/,
  /^empresa-creada\/?$/,
  /^email-confirmado\/?$/,
];

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  const access_token = localStorage.getItem("access_token") || "";
  const created_at = parseInt(localStorage.getItem("created_at")) || "";
  const expires_in = parseInt(localStorage.getItem("expires_in")) || "";
  const now = new Date();

  EventBus.$emit("clearMessages");

  //const authRequired = !publicPages.includes(to.path);
  const authRequired = !publicPages.some(function (e) {
    return e.test(to.path.substring(1));
  });

  // redirect to login page if not logged in and trying to access a restricted page
  if (
    authRequired &&
    (!auth.getters.isLoggedIn ||
      !access_token ||
      !created_at ||
      !expires_in ||
      new Date(new Date(created_at).setSeconds(new Date(created_at).getSeconds() + expires_in)) < now)
  ) {
    auth.dispatch("logout");
    return next("/entrar");
  }

  if (authRequired) {
    const curUser = JSON.parse(auth.getters.curUser);
    if (!curUser.roles.find((o) => o.name === "person") && !curUser.roles.find((o) => o.name === "company")) {
      auth.dispatch("logout");
      $("#preloader").fadeOut();
      return next("/entrar");
    }
  }

  if (
    authRequired &&
    new Date(new Date(created_at).setSeconds(new Date(created_at).getSeconds() + expires_in - 600)) < now
  ) {
    const refresh_token = localStorage.getItem("refresh_token") || "";
    auth.dispatch("refresh", refresh_token);
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

  if (to.matched.some((record) => record.meta.rolePerson)) {
    const curUser = JSON.parse(auth.getters.curUser);

    if (!curUser.is_person) {
      next({ name: "home" });
    }
  }

  window.scrollTo(0, 0);

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

// This callback runs after every route change, including on page load. This hook do not get a next function and cannot affect the navigation
/*router.afterEach((to, from) => {
  const authRequired = !publicPages.some(function (e) {
    return e.test(to.path.substring(1));
  });

  if (! authRequired)
    return
})*/

export default router;
