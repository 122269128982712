import { render, staticRenderFns } from "./New.vue?vue&type=template&id=40e504e2&scoped=true&"
import script from "./New.vue?vue&type=script&lang=js&"
export * from "./New.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/content.scss?vue&type=style&index=0&id=40e504e2&prod&lang=scss&scoped=true&"
import style1 from "./New.vue?vue&type=style&index=1&id=40e504e2&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e504e2",
  null
  
)

export default component.exports