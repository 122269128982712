import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=70bb8253&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "@/assets/repay/css/fonts.css?vue&type=style&index=0&id=70bb8253&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70bb8253",
  null
  
)

export default component.exports