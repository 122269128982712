<template>
  <!-- Footer -->
  <section class="footer-section">
    <!-- <SectionPartners /> -->
    <div class="container">
      <div class="middle-portion">
        <div class="row">
          <div class="col-lg-3 col-md-5 col-sm-4 col-12">
            <a href="./index.html">
              <figure class="footer-logo">
                <img src="@/assets/repay/images/aless-pay-logo.png" class="" alt="" />
              </figure>
            </a>
            <p v-html="$t('footer.text')" class="text-size-16 footer-text"></p>
            <!-- <figure class="mb-0 payment-icon">
              <img src="@/assets/repay/images/payment-card.png" class="img-fluid" alt="" />
            </figure> -->
          </div>
          <div class="col-lg-1 col-md-1 col-sm-12 col-12 d-lg-block d-none"></div>
          <div class="col-lg-2 col-md-3 col-sm-12 col-12 d-md-block d-none">
            <div class="links">
              <h4 v-html="$t('footer.important_label')" class="heading"></h4>
              <hr class="line" />
              <ul class="list-unstyled mb-0">
                <li>
                  <router-link
                    to="/#home"
                    class="text-size-16 text text-decoration-none"
                    @click.native="scrollFix('#home')"
                    v-html="$t('links.home')"
                  ></router-link>
                </li>
                <li>
                  <router-link
                    to="/#what_we_do"
                    class="text-size-16 text text-decoration-none"
                    @click.native="scrollFix('#what_we_do')"
                    v-html="$t('links.what_we_do')"
                  >
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/#about_us"
                    class="text-size-16 text text-decoration-none"
                    @click.native="scrollFix('#about_us')"
                    v-html="$t('links.about_us')"
                  >
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/#services"
                    class="text-size-16 text text-decoration-none"
                    @click.native="scrollFix('#services')"
                    v-html="$t('links.services')"
                  >
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/#faq"
                    class="text-size-16 text text-decoration-none"
                    @click.native="scrollFix('#faq')"
                    v-html="$t('links.faq')"
                  >
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/#contact_us"
                    class="text-size-16 text text-decoration-none"
                    @click.native="scrollFix('#contact_us')"
                    v-html="$t('links.contact_us')"
                  >
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-2 col-sm-4 col-12 d-lg-block d-sm-block">
            <div class="links">
              <h4 v-html="$t('footer.support_label')" class="heading"></h4>
              <hr class="line" />
              <ul class="list-unstyled mb-0">
                <li>
                  <router-link
                    class="text-size-16 text text-decoration-none"
                    to="/terminos-y-condiciones"
                    v-html="$t('links.term_condition')"
                  ></router-link>
                </li>
                <li>
                  <!-- <a href="./pricing.html" class="text-size-16 text text-decoration-none">Plan</a> -->
                  <router-link
                    class="text-size-16 text text-decoration-none"
                    to="/privacidad"
                    v-html="$t('links.privacy')"
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 col-12 d-sm-block">
            <div class="icon">
              <h4 class="heading" v-html="$t('footer.get_touch')"></h4>
              <hr class="line" />
              <ul class="list-unstyled mb-0">
                <!-- <li class="text-size-16 text">
                  Email:
                  <a href="mailto:info@repay.com" class="mb-0 text text-decoration-none text-size-16">info@repay.com</a>
                </li> -->
                <li class="text-size-16 text">
                  {{ $t("footer.phone") }}:
                  <a href="tel:+4733378901" class="mb-0 text text-decoration-none text-size-16">+1 (786) 851 7689</a>
                </li>
                <!-- <li class="text-size-16 text1">
                  Fax:
                  <a href="tel:+198765432199" class="mb-0 text text-decoration-none text-size-16"
                    >+1 (987) 654 321 9 9</a
                  >
                </li> -->
                <!-- <li class="social-icons">
                  <div class="circle">
                    <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                  </div>
                  <div class="circle">
                    <a href="#"><i class="fa-brands fa-twitter"></i></a>
                  </div>
                  <div class="circle">
                    <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                  </div>
                  <div class="circle">
                    <a href="#"><i class="fa-brands fa-pinterest"></i></a>
                  </div>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import SectionPartners from "@/components/sections/repay/Partners";

export default {
  components: {
    // SectionPartners,
  },
  data() {
    return {
      errors: [],
    };
  },
  mixins: [],
  mounted() {
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  methods: {
    scrollFix: function (hashbang) {
      location.hash = hashbang;
      if (this.$route.hash) {
        location.href = this.$route.hash;

        const innerWidth = $(window).innerWidth();
        if (innerWidth < 991) {
          $("#menu_close").trigger("click");
          setTimeout(function () {
            const scrollTop = window.pageYOffset || $(hashbang).offset().top;
            $("html, body").animate(
              {
                scrollTop: scrollTop - 100,
              },
              300
            );
          }, 10);
        } else {
          const scrollTop = window.pageYOffset || $(hashbang).offset().top;
          window.scrollTo(0, scrollTop - 100);
        }
      }
    },
    home: function (route) {
      this.$router.push(route);
    },
  },
};
</script>
