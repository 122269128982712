var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"what-we-do about-section position-relative",attrs:{"id":"about_us"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"subheading",attrs:{"data-aos":"fade-right"}},[_c('h6',{directives:[{name:"t",rawName:"v-t",value:('about_us.title'),expression:"'about_us.title'"}]}),_c('h2',{directives:[{name:"t",rawName:"v-t",value:('about_us.description'),expression:"'about_us.description'"}]})])])]),_c('div',{staticClass:"row position-relative"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-12"},[_c('div',{staticClass:"service1"},[_vm._m(1),_c('h3',{directives:[{name:"t",rawName:"v-t",value:('about_us.vision_title'),expression:"'about_us.vision_title'"}]}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('about_us.vision_description'),expression:"'about_us.vision_description'"}],staticClass:"mb-0 text-size-18"})])]),_vm._m(2),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-12"},[_c('div',{staticClass:"service1 service2"},[_vm._m(3),_c('h3',{directives:[{name:"t",rawName:"v-t",value:('about_us.mission_title'),expression:"'about_us.mission_title'"}]}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('about_us.mission_description'),expression:"'about_us.mission_description'"}],staticClass:"mb-0 text-size-18"})])]),_vm._m(4),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-12"},[_c('div',{staticClass:"service1"},[_vm._m(5),_c('h3',{directives:[{name:"t",rawName:"v-t",value:('about_us.strategy_title'),expression:"'about_us.strategy_title'"}]}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('about_us.strategy_description'),expression:"'about_us.strategy_description'"}],staticClass:"mb-0 text-size-18"})])]),_vm._m(6)]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"element1 mb-0"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/repay/images/what-we-do-icon-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"img img1"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/repay/images/vision-icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"arrow1 mb-0",attrs:{"data-aos":"fade-down"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/repay/images/what-we-do-arrow-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"img img2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/repay/images/mission-icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"arrow2 mb-0",attrs:{"data-aos":"fade-up"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/repay/images/what-we-do-arrow-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"img img3"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/repay/images/strategyicon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"element3 mb-0"},[_c('img',{attrs:{"src":require("@/assets/repay/images/what-we-do-element.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"element2 mb-0"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/repay/images/what-we-do-icon-2.png"),"alt":""}})])
}]

export { render, staticRenderFns }