<template>
  <div class="main-layout">
    <section class="position-relative">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="py-4">{{ $t("privacy.title") }}</h1>
            <div>
              <h2>{{ $t("privacy.sub_title") }}</h2>
              <p>{{ $t("privacy.description") }}</p>
              <h2>{{ $t("privacy.block1_title") }}</h2>
              <p>
                {{ $t("privacy.block1_p1") }}
              </p>
              <p>
                {{ $t("privacy.block1_p2") }}
              </p>
              <p>
                {{ $t("privacy.block1_p3") }}
              </p>
              <p>
                {{ $t("privacy.block1_p4") }}
              </p>
              <h2>{{ $t("privacy.block2_title") }}</h2>
              <p>
                {{ $t("privacy.block2_p1") }}
              </p>
              <p>
                <ul>
                  <li><p>{{ $t("privacy.block2_p2") }}</p></li>
                  <li>{{ $t("privacy.block2_p3") }}</li>
                  <li>
                    {{ $t("privacy.block2_p4") }}
                    <ul>
                      <li>{{ $t("privacy.block2_p5") }}</li>
                      <li>{{ $t("privacy.block2_p6") }}</li>
                      <li>{{ $t("privacy.block2_p7") }}</li>
                    </ul>
                  </li>
                  <li>{{ $t("privacy.block2_p8") }}</li>
                  <li>{{ $t("privacy.block2_p9") }}</li>
                  <li>{{ $t("privacy.block2_p10") }}</li>
                  <li>{{ $t("privacy.block2_p11") }}</li>
                </ul>
              </p>
              <h3>{{ $t("privacy.block3_title") }}</h3>
              <p>
                {{ $t("privacy.block3_p1") }}
              </p>
              <h3>{{ $t("privacy.block4_title") }}</h3>
              <p>
                {{ $t("privacy.block4_p1") }}
              </p>
              <p>
              <ul>
                <li>
                  {{ $t("privacy.block4_p2") }}
                  <ul>
                    <li>{{ $t("privacy.block4_p3") }}</li>
                    <li>{{ $t("privacy.block4_p4") }}</li>
                    <li>{{ $t("privacy.block4_p5") }}</li>
                    <li>{{ $t("privacy.block4_p6") }}</li>
                    <li>{{ $t("privacy.block4_p7") }}</li>
                    <li>{{ $t("privacy.block4_p8") }}</li>
                    <li>{{ $t("privacy.block4_p9") }}</li>
                    <li>{{ $t("privacy.block4_p10") }}</li>
                    <li>{{ $t("privacy.block4_p11") }}</li>
                    <li>{{ $t("privacy.block4_p12") }}</li>
                    <li>{{ $t("privacy.block4_p13") }}</li>
                    <li>{{ $t("privacy.block4_p14") }}</li>
                    <li>{{ $t("privacy.block4_p15") }}</li>
                    <li>{{ $t("privacy.block4_p16") }}</li>
                  </ul>
                </li>
              </ul>
            </p>
              <h3>{{ $t("privacy.block5_title") }}</h3>
              <p>
                {{ $t("privacy.block5_p1") }}
              </p>
              <p>
                {{ $t("privacy.block5_p2") }}
              </p>
              <p>
                {{ $t("privacy.block5_p3") }}
              </p>
              <p>{{ $t("privacy.block5_p4") }}</p>
              <p>{{ $t("privacy.block5_p5") }}</p>
              <p>
              <ul>
                <li>{{ $t("privacy.block5_p6") }}</li>
                <li>{{ $t("privacy.block5_p7") }}</li>
                <li>{{ $t("privacy.block5_p8") }}</li>
              </ul>
              </p>
              <p>
                {{ $t("privacy.block5_p9") }}
              </p>
              <p>
              <ul>
                <li>{{ $t("privacy.block5_p10") }}</li>
                <li>{{ $t("privacy.block5_p11") }}</li>
                <li>{{ $t("privacy.block5_p12") }}</li>
                <li>{{ $t("privacy.block5_p13") }}</li>
                <li>{{ $t("privacy.block5_p14") }}</li>
                <li>{{ $t("privacy.block5_p15") }}</li>
                <li>{{ $t("privacy.block5_p16") }}</li>
                <li>{{ $t("privacy.block5_p17") }}</li>
                <li>{{ $t("privacy.block5_p18") }}</li>
                <li>{{ $t("privacy.block5_p19") }}</li>
                <li>{{ $t("privacy.block5_p20") }}</li>
              </ul>
              </p>
              <p>{{ $t("privacy.block5_p21") }}</p>
              <p>{{ $t("privacy.block5_p22") }}</p>
              <h3>{{ $t("privacy.block6_title") }}</h3>
              <p>
                {{ $t("privacy.block6_p1") }}
              </p>
              <p>
              <ul>
                <li>{{ $t("privacy.block6_p2") }}</li>
                <li>{{ $t("privacy.block6_p3") }}</li>
                <li>{{ $t("privacy.block6_p4") }}</li>
              </ul>
              </p>
              <p>
                {{ $t("privacy.block6_p5") }}
              </p>
              <p>{{ $t("privacy.block6_p6") }}</p>
              <h3>{{ $t("privacy.block7_title") }}</h3>
              <p>
                {{ $t("privacy.block7_p1") }}
              </p>
              <p>{{ $t("privacy.block7_p2") }}</p>
              <p>{{ $t("privacy.block7_p3") }}</p>
              <h2>{{ $t("privacy.block8_title") }}</h2>
              <p>
                {{ $t("privacy.block8_p1") }}
              </p>
              <p>
              <ul>
                <li>
                  {{ $t("privacy.block8_p2") }}
                  <ul>
                    <li>{{ $t("privacy.block8_p3") }}</li>
                    <li>{{ $t("privacy.block8_p4") }}</li>
                  </ul>
                </li>
                <li>
                  {{ $t("privacy.block8_p5") }}
                  <ul>
                    <li>{{ $t("privacy.block8_p6") }}</li>
                    <li>{{ $t("privacy.block8_p7") }}</li>
                  </ul>
                </li>
                <li>
                  {{ $t("privacy.block8_p8") }}
                  <ul>
                    <li>
                      {{ $t("privacy.block8_p9") }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{ $t("privacy.block8_p10") }}
                </li>
                <li>
                  {{ $t("privacy.block8_p11") }}
                </li>
                <li>{{ $t("privacy.block8_p12") }}</li>
                <li>{{ $t("privacy.block8_p13") }}</li>
                <li>
                  {{ $t("privacy.block8_p14") }}
                  <ul>
                    <li>
                      {{ $t("privacy.block8_p15") }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{ $t("privacy.block8_p16") }}
                  <ul>
                    <li>
                      {{ $t("privacy.block8_p17") }}
                      <ul>
                        <li>{{ $t("privacy.block8_p18") }}</li>
                        <li>{{ $t("privacy.block8_p19") }}</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              </p>
              <h3>{{ $t("privacy.block9_title") }}</h3>
              <p>
                {{ $t("privacy.block9_p1") }}
              </p>
              <h3>{{ $t("privacy.block10_title") }}</h3>
              <p>
                {{ $t("privacy.block10_p1") }}
              </p>
              <h3>{{ $t("privacy.block11_title") }}</h3>
              <h5>{{ $t("privacy.block11_sub_title") }}</h5>
              <p>
                {{ $t("privacy.block11_p1") }}
              </p>
              <p>{{ $t("privacy.block11_p2") }}</p>
              <h5>{{ $t("privacy.block11_sub_title2") }}</h5>
              <p>
                {{ $t("privacy.block11_p3") }}
              </p>
              <h3>{{ $t("privacy.block12_title") }}</h3>
              <p>
                {{ $t("privacy.block12_p1") }}
              </p>
              <p>
                {{ $t("privacy.block12_p2") }}
              </p>
              <p>
              <ul>
                <li>{{ $t("privacy.block12_p3") }}</li>
              </ul>
              </p>
              <h3>{{ $t("privacy.block13_title") }}</h3>
              <p>
                {{ $t("privacy.block13_p1") }}
              </p>
              <p>{{ $t("privacy.block13_p2") }}</p>
              <p>{{ $t("privacy.block13_p3") }}</p>
              <p>{{ $t("privacy.block13_p4") }}</p>
              <p>{{ $t("privacy.block13_p5") }}</p>
              <p>{{ $t("privacy.block13_p6") }}</p>
              <p>{{ $t("privacy.block13_p7") }}</p>
              <p>{{ $t("privacy.block13_p8") }}</p>
              <p>{{ $t("privacy.block13_p9") }}</p>
              <p>{{ $t("privacy.block13_p10") }}</p>
              <h3>{{ $t("privacy.block14_title") }}</h3>
              <p>
                {{ $t("privacy.block14_p1") }}
              </p>
              <h5>
                {{ $t("privacy.recaptcha") }}
              </h5>
              <p>
                {{ $t("privacy.recaptcha_p1") }}
              </p>
              <p>{{ $t("privacy.recaptcha_p2") }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SectionFooter />
  </div>
</template>

<script>
import SectionFooter from "@/components/sections/repay/Footer";
export default {
  components: {
    SectionFooter,
  },
};
</script>
