<template>
  <!--Header-->
  <div class="fixed-top">
    <TopBar />
    <header class="header">
      <div class="container">
        <nav class="navbar position-relative navbar-expand-lg navbar-light">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <a class="navbar-brand" href="/">
            <figure class="mb-0">
              <img src="@/assets/repay/images/aless-pay-logo.png" alt="" class="" /></figure
          ></a>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
            <span class="navbar-toggler-icon"></span>
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto ml-mobile">
              <li class="nav-item">
                <router-link
                  to="/#what_we_do"
                  class="nav-link"
                  @click.native="scrollFix('#what_we_do')"
                  v-html="$t('links.what_we_do')"
                >
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/#about_us"
                  class="nav-link"
                  @click.native="scrollFix('#about_us')"
                  v-html="$t('links.about_us')"
                >
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/#services"
                  class="nav-link"
                  @click.native="scrollFix('#services')"
                  v-html="$t('links.services')"
                >
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/#faq" class="nav-link" @click.native="scrollFix('#faq')" v-html="$t('links.faq')">
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/#contact_us"
                  class="nav-link"
                  @click.native="scrollFix('#contact_us')"
                  v-html="$t('links.contact_us')"
                >
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle dropdown-color navbar-text-color"
                  href="#"
                  id="navbarDropdownMenu"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ $t("header.clients_area") }}
                </a>
                <div class="dropdown-menu drop-down-content">
                  <ul class="list-unstyled drop-down-pages">
                    <li class="nav-item">
                      <router-link
                          @click.native="closeMenu"
                          class="dropdown-item nav-link"
                          to="/entrar">
                        {{ $t("header.login") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                          @click.native="closeMenu"
                          class="dropdown-item nav-link"
                          to="/registrarse">
                        {{ $t("header.register") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle dropdown-color navbar-text-color"
                  href="#"
                  id="navbarDropdownMenu"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-globe"></i>
                  {{ $i18n.locale }}
                </a>
                <div class="dropdown-menu drop-down-content lang">
                  <ul class="list-unstyled drop-down-pages">
                    <li class="nav-item" v-for="locale in $i18n.availableLocales" v-bind:key="locale">
                      <a class="dropdown-item nav-link" href="javascript:void(0);" @click="changeLang(locale)">
                        {{ locale }}</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<style src="@/assets/repay/css/fonts.css" lang="css" scoped></style>

<script>
import global from "@/helpers/global";
import TopBar from "@/components/sections/TopBar";

export default {
  mixins: [global],
  components: {
    TopBar,
  },
  mounted() {
    // From testing, without a brief timeout, it won't work.
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  methods: {
    scrollFix: function (hashbang) {
      location.hash = hashbang;
      if (this.$route.hash) {
        location.href = this.$route.hash;

        const innerWidth = $(window).innerWidth();
        if (innerWidth < 991) {
          this.closeMenu();
          setTimeout(function () {
            const scrollTop = window.pageYOffset || $(hashbang).offset().top;
            $("html, body").animate(
              {
                scrollTop: scrollTop - 100,
              },
              300
            );
          }, 10);
        } else {
          const scrollTop = window.pageYOffset || $(hashbang).offset().top;
          window.scrollTo(0, scrollTop - 100);
        }
      }
    },
    changeLang: function (lang) {
      this.$i18n.locale = lang;
      this.closeMenu();
    },
    closeMenu: function () {
      $("#navbarSupportedContent").removeClass("show");
    },
  },
};
</script>
