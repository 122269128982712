<template>
  <!-- Page Content -->
  <div class="content main-layout login-layout">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <!-- Login Tab Content -->
          <div class="account-login">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-7 login-left">
                <img src="@/assets/img/login-graphic01.png" class="img-fluid" alt="Doccure Login" />
              </div>
              <div class="col-md-12 col-lg-5 login-right">
                <div class="login-header">
                  <h3>Autenticarse</h3>
                </div>
                <form @submit.prevent="auth">
                  <div class="form-group form-focus" :class="{ focused: focused }">
                    <input
                      v-model="username"
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      @focus="focused = true"
                      @blur="focused = false"
                    />
                    <small v-if="errors.username" class="text-danger">{{ errors.username[0] }}</small>
                    <label class="focus-label">Usuario</label>
                  </div>
                  <div class="form-group form-focus" :class="{ focused: focused }">
                    <input
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control floating"
                      placeholder=" "
                      @focus="focused = true"
                      @blur="focused = false"
                    />
                    <small v-if="errors.password" class="text-danger">{{ errors.password[0] }}</small>
                    <label class="focus-label">Contraseña</label>
                    <span class="showPassword" @click="showPassword = !showPassword"
                      ><i class="fa" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i
                    ></span>
                  </div>
                  <div class="text-right">
                    <router-link class="forgot-link" to="/recuperar-contrasena">¿Olvidaste la contraseña?</router-link>
                  </div>
                  <button class="btn btn-primary btn-block btn-lg login-btn" type="submit">Entrar</button>
                  <div class="login-or">
                    <span class="or-line"></span>
                    <span class="span-or">o</span>
                  </div>
                  <div class="text-center dont-have">
                    ¿No tienes una cuenta?
                    <router-link to="/registrarse">Regístrate</router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- /Login Tab Content -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>

<style src="@/assets/scss/frontend/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import auth from "@/helpers/auth";
import global from "@/helpers/global";

export default {
  data() {
    return {
      focused: false,
      username: "",
      password: "",
      showPassword: false,
      errors: [],
    };
  },
  mixins: [global],
  mounted() {
    if (this.checkIfLoggedIn()) this.$router.push("/monedero");
  },
  methods: {
    auth() {
      $("#preloader").fadeIn();

      let username = this.username;
      let password = this.password;
      auth
        .dispatch("login", {
          username,
          password,
        })
        .then(() => this.$router.push("/monedero"))
        .catch((error) => {
          this.errors = [];

          this.username = "";
          this.password = "";
          this.showPassword = false;
          this.focused = false;

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error ?? "Su usuario y/o contraseña no son correctos.",
            });

            console.log(error.response.data.error);
          }

          $("#preloader").fadeOut();
        });
    },
  },
};
</script>
