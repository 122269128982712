<template>
  <div class="container-fluid">
    <div class="page-titles">
      <h2 class="text-black font-w600">
        Transferir
        <div v-show="walletBalance != ''" class="flex-row-reverse float-right ml-md-auto align-self-md-end pl-1">
          <span class="text-sm font-weight-bold">Saldo </span>
          <span v-if="walletBalance" class="badge light badge-light py-0">{{ formatCurrency(walletBalance) }}</span>
          <span v-else class="badge light badge-light py-0">$0.00</span>
        </div>
      </h2>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="transfer" method="POST">
                  <div class="row">
                    <div v-if="sendToUser" class="col-md-6">
                      <label for="username">Usuario</label>
                      <input type="text" class="form-control" v-model="dataTransfer.username" disabled />
                      <small v-if="errors.username" class="text-danger">{{ errors.username[0] }}</small>
                    </div>
                    <div v-else class="col-md-4">
                      <label for="email">Email</label>
                      <v-combobox
                        v-model="dataTransfer.email"
                        v-lower-case
                        class="form-control"
                        :loading="loading"
                        :items="emails"
                        :search-input.sync="searchByEmail"
                        solo-inverted
                        hide-details
                        hide-no-data
                        clearable
                        flat
                      ></v-combobox>
                      <small v-if="errors.email" class="text-danger">{{ errors.email[0] }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <label for="currency">Moneda</label>
                      <v-autocomplete
                        class="form-control"
                        :items="currencies"
                        flat
                        hide-details
                        hide-no-data
                        solo-inverted
                        item-text="acronym"
                        item-value="id"
                        v-model="dataTransfer.currency_id"
                        @change="processPayment()"
                      ></v-autocomplete>
                      <small v-if="errors.currency_id" class="text-danger">{{ errors.currency_id[0] }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <label for="amount">Monto</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          step="0.01"
                          min="0.01"
                          v-model="dataTransfer.amount"
                          @change="processPayment()"
                        />
                      </div>
                      <small v-if="errors.amount" class="text-danger">{{ errors.amount[0] }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label for="comments">Descripción</label>
                      <textarea class="form-control" rows="4" v-model="dataTransfer.comments"></textarea>
                      <small v-if="errors.comments" class="text-danger">{{ errors.comments[0] }}</small>
                    </div>
                  </div>
                  <div v-if="checkoutData">
                    <hr class="mb-4" />
                    <h4 class="fs-18 mb-3">Total a pagar</h4>
                    <ul class="list-group mb-3 pl-0">
                      <li class="list-group-item d-flex justify-content-between">
                        <div>
                          <h6 class="my-0">Monto a transferir</h6>
                          <small class="text-muted">Total a enviar al usuario</small>
                        </div>
                        <span class="text-muted">{{ formatCurrency(checkoutData.amount) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">Comisión Aless Pay</h6>
                          <small class="text-muted">Cobrada por el sistema</small>
                        </div>
                        <span class="text-muted">{{ formatCurrency(checkoutData.system) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between">
                        <span>Total (USD)</span>
                        <strong>{{ formatCurrency(checkoutData.final_amount) }}</strong>
                      </li>
                    </ul>
                  </div>
                  <hr class="mb-4" />
                  <div class="custom-control custom-checkbox mb-2">
                    <input
                      v-model="dataTransfer.accept_terms"
                      type="checkbox"
                      class="custom-control-input"
                      id="same-address"
                    />
                    <label class="custom-control-label" for="same-address"
                      >Términos y Condiciones. Haga clic
                      <a href="javascript:void();" data-toggle="modal" data-target="#termsModal">aquí</a>
                      para ver.</label
                    >
                  </div>
                  <small v-if="errors.accept_terms" class="d-block text-danger">{{ errors.accept_terms[0] }}</small>
                  <button class="btn btn-primary btn-lg btn-block mt-8 col-md-6 mx-auto" type="submit">Enviar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="col-xl-5">
        <div class="row">
          <div class="col-xl-12 col-lg-6 col-md-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <div>
                  <h4 class="fs-20 text-black font-w600">
                    Información
                  </h4>
                  <div class="p-3 bgl-dark rounded fs-14 d-flex">
                    <svg
                      class="mr-3 min-w24"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85384C4.07979 4.06253 2.66989 5.7805 1.83733 7.79049C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7887C11.9878 23.2131 14.1995 22.9953 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9966 9.08368 21.8365 6.28778 19.7744 4.22563C17.7122 2.16347 14.9163 1.00344 12 1ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C20.9971 14.3861 20.0479 16.6736 18.3608 18.3608C16.6736 20.048 14.3861 20.9971 12 21Z"
                        fill="#A4A4A4"
                      />
                      <path
                        d="M12 9C11.7348 9 11.4804 9.10536 11.2929 9.29289C11.1054 9.48043 11 9.73478 11 10V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8947 17.5196 13 17.2652 13 17V10C13 9.73478 12.8947 9.48043 12.7071 9.29289C12.5196 9.10536 12.2652 9 12 9Z"
                        fill="#A4A4A4"
                      />
                      <path
                        d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7C11 7.55228 11.4477 8 12 8Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                    <p class="mb-0">
                      Por transferir saldo cobramos 1,50% de comisión.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body"></div>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      currencies: [
        {
          id: 1,
          acronym: "USD",
        },
      ],
      dataTransfer: {
        email: "",
        username: "",
        currency_id: 1,
        amount: "",
        comments: "",
        accept_terms: false,
      },
      walletBalance: "",
      sendToUser: false,
      checkoutData: null,
      emails: [],
      searchByEmail: null,
      loading: false,
      errors: [],
      cancelTokenSource: null,
    };
  },
  mixins: [global],
  beforeMount() {
    if (this.$route.params.username) {
      this.sendToUser = true;
      delete this.dataTransfer["email"];
      this.dataTransfer.username = this.$route.params.username;
    } else {
      delete this.dataTransfer["username"];
    }

    if (this.curUser.person != undefined) {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/person/get/" + this.curUser.person.id)
        .then((response) => {
          this.walletBalance = response.data.person.wallet.amount;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          this.walletBalance = "";

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            console.log(error.response.data.error);
          }
        });
    } else if (this.curUser.company != undefined) {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/company/get/" + this.curUser.company.id)
        .then((response) => {
          this.walletBalance = response.data.company.wallet.amount;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          this.walletBalance = "";

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            console.log(error.response.data.error);
          }
        });
    }
  },
  mounted() {
    this.getEmailFromRouteParam();
  },
  methods: {
    transfer: function () {
      this.errors = [];

      if ($("button[type=submit]").hasClass("disabled")) {
        return false;
      }

      $("button[type=submit]").addClass("disabled");

      axios
        .post(process.env.VUE_APP_API_URL + "/api/v1/transfers/new", this.dataTransfer)
        .then((response) => {
          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/transferencias");
          $("button[type=submit]").removeClass("disabled");
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            $("button[type=submit]").removeClass("disabled");

            console.log(error.response.data.error);
          }
        });
    },
    processPayment: function () {
      this.errors = [];
      if (this.dataTransfer.amount) {
        axios
          .post(process.env.VUE_APP_API_URL + "/api/v1/transfers/commissions", this.dataTransfer)
          .then((response) => {
            this.checkoutData = response.data.commissions;
            //console.log(response.data.message);
          })
          .catch((error) => {
            this.errors = [];

            this.checkoutData = null;

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];

              EventBus.$emit("showErrors", {
                msg: error.response.data.error,
              });

              console.log(error.response.data.error);
            }
          });
      }
    },
    cancelSearchByEmail() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("cancel current search");
      }
    },
    async searchEmails(text) {
      try {
        this.cancelSearchByEmail();
        this.cancelTokenSource = axios.CancelToken.source();
        this.loading = true;

        const getEmailUrl = `${process.env.VUE_APP_API_URL}/api/v1/list/email/with-transfers`;
        const {
          data: { emails },
        } = await axios.post(getEmailUrl, { email_like: text }, { cancelToken: this.cancelTokenSource.token });

        this.emails = emails;

        this.loading = false;
      } catch ({
        response: {
          data: { error },
        },
      }) {
        EventBus.$emit("showErrors", {
          msg: error,
        });
      }
    },
    getEmailFromRouteParam() {
      this.dataTransfer.email = this.$route.params.user_email || "";
    },
  },

  watch: {
    searchByEmail(value, oldValue) {
      const valueLowerCase = this.$options.filters.lowerCase(value);

      value && valueLowerCase !== oldValue && value.length >= 3 && this.searchEmails(value);
    },
  },
};
</script>
